<template>
  <div class="container-horizontal" style="height: 100vh">
    <div class="pane pane-left">
      <div class="container-vertical">
        <div class="pane pane-server-selection">
          <div class="panel">
            <h3 class="heading">Server selection</h3>
            <div class="select">
              <select v-model="currentServerIP">
                <option value="86.126.139.126">Test Server (86.126.139.126)</option>
              </select>
            </div>
            <input class="input is-primary" type="text" placeholder="Key" v-model="currentServerKey">
          </div>
        </div>
        <div class="pane pane-server-info">
          <div class="panel">
            <ServerInfo
              :baseUrl="baseUrl"
              :secret="currentServerKey" />
          </div>
        </div>
      </div>
    </div>
    <div class="pane pane-right">
      <div class="container-vertical">
        <div class="pane">
          <div class="panel">
            <ControlServiceLog
              :baseUrl="baseUrl"
              :secret="currentServerKey" />
          </div>
        </div>
        <div class="pane">
          <div class="panel">
            <UnrealLog
              :baseUrl="baseUrl"
              :secret="currentServerKey" />
          </div>
        </div>
        <div class="pane">
          <div class="panel">
            <RequestLog :log="requestLog" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ControlServiceLog from '@/components/ControlServiceLog.vue'
import UnrealLog from '@/components/UnrealLog.vue'
import RequestLog from '@/components/RequestLog.vue'
import ServerInfo from '@/components/ServerInfo.vue'

export default {
  components: {
    ControlServiceLog,
    UnrealLog,
    ServerInfo,
    RequestLog
  },
  computed: {
    baseUrl () {
      return `http://${this.currentServerIP}:${this.currentServerPort}`
    }
  },
  data () {
    return {
      currentServerIP: '',
      currentServerPort: '6300',
      currentServerKey: 'test',
      requestLog: []
    }
  },
  watch: {
    currentServerKey () {
      window.localStorage.setItem('secret', this.currentServerKey)
    }
  },
  methods: {
    log (message, data) {
      this.requestLog.push({
        time: Math.floor(Date.now() / 1000),
        msg: message,
        data: data ? data : false
      })
    }
  },
  created () {
    if (window.localStorage.getItem('secret')) {
      this.currentServerKey = window.localStorage.getItem('secret')
    }
  }
}
</script>

<style>
@import "https://cdn.jsdelivr.net/npm/bulma@0.9.3/css/bulma.min.css";

body,html {
  height: 100vh;
  overflow-y: auto;
}

body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

.container-horizontal {
  display: flex;
  height: 100%;
  flex-direction: row;
}

.container-vertical {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.pane {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0px;
  box-sizing: border-box;
  min-width: 0;
  min-height: 0;
}

.pane-server-selection {
  flex-grow: .25;
  flex-shrink: .25;
}

.pane-server-info {
  flex-grow: .75;
  flex-shrink: .75;
}

.pane-left {
  flex-grow: .3;
  flex-shrink: .3;
}

.pane-right {
  flex-grow: .7;
  flex-shrink: .7;
}

.panel {
  background-color: #eee;
  border-radius: 5px;
  width: auto;
  height: calc(100% - 10px);
  box-sizing: border-box;
  margin: 5px;
  overflow: hidden;
}

.heading {
  margin-top: 0;
  padding: 10px;
  text-align: left;
}

h3.heading {
  font-size: 13px;
}

input, .select, textarea, button {
  margin: 5px;
}

input {
  width: calc(100% - 10px) !important;
}

button {
  cursor: pointer;
}

.toggled-on {
  font-weight: bold;
}

.toggled-off {
  font-weight: normal;
}

.log {
  height: calc(33vh - 70px);
  max-height: calc(33vh - 70px);
  background-color: white;
  overflow: auto;
}

.no-data {
  display: block;
  font-size: 13px;
  color: #ccc;
  text-align: center;
  padding: 20px;
}

.log .log-message {
  font-size: 10px;
  padding: 1px 3px;
}

.log .log-message:nth-child(odd) {
  background-color: #f5f5f5;
}

.log-message .data {
  cursor: help;
  background-color:lightcyan;
  padding: 1px 3px;
  border-radius: 3px;
}

.log-message .time {
  cursor: help;
  background-color:lightyellow;
  padding: 1px 3px;
  border-radius: 3px;
}

table {
  background-color: white;
}

table, tbody, tr, td {
  display: block;
  box-sizing: border-box;
}

td {
  border-bottom: 1px solid #eee;
  padding: 3px 10px;
}

tr:nth-child(even) {
  background-color: #f5f5f5;
}

td span {
  float: right;
}

</style>
