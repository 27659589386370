<template>
  <h3 class="heading">Server Control</h3>
  <button v-if="enableStartButton" class="button is-primary" @click="requestStartup()">Start</button>
  <button v-else class="button is-primary" disabled>Start</button>
  <button v-if="enableStopButton" class="button is-danger" @click="requestShutdown()">Stop</button>
  <button v-else class="button is-danger" disabled>Stop</button>

  <h3 class="heading">Server Info</h3>
  <table v-if="info">
    <tbody>
      <tr v-for="(value, index) in info" v-bind:key="index">
        <td><strong>{{ index }}</strong><span>{{ value }}</span></td>
      </tr>
    </tbody>
  </table>
  <span v-else class="no-data">No data.</span>
  <div class="content small">
    <ul>
      <li v-if="baseUrlDefined()">
        <a :href="baseUrl" target="_blank">Status URL</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    baseUrl: String,
    secret: String,
  },
  data () {
    return {
      info: null,
      requestingStartup: false,
      requestingShutdown: false,
    }
  },
  computed: {
    enableStartButton () {
      if (this.requestingStartup) {
        return false
      }

      if (!this.info) {
        return false
      }

      if (!this.info.online) {
        return true
      }

      if (this.baseUrl.length >= 20) {
        return false
      }

      return true
    },
    enableStopButton () {
      if (this.requestingShutdown) {
        return false
      }

      if (!this.info) {
        return false
      }

      if (this.info.online) {
        return true
      }

      if (this.baseUrl.length >= 20) {
        return false
      }

      return true
    }
  },
  watch: {
    baseUrl () {
      this.reload()
    }
  },
  methods: {
    baseUrlDefined () {
      return (this.baseUrl.length >= 20)
    },
    async reload () {
      console.log('Refreshing server info...')

      let url = this.baseUrl + '/'
      let params = {
        key: this.secret
      }

      try {
        let response = await this.$http.get(url, {
          params: params
        })

        this.info = response.data || []
      } catch (e) {
        this.info = []
      }
    },
    async requestStartup () {
      this.requestingStartup = true

      console.log('Requesting startup...')

      let url = this.baseUrl + '/startup'
      let params = {
        key: this.secret
      }

      this.$parent.log('Requesting startup...', { url, params })

      try {
        await this.$http.get(url, {
          params: params
        })

        this.$parent.log('The server has been started up.')
      } catch (e) {
        this.$parent.log('Startup failed.', e)
      } finally {
        this.requestingStartup = false
      }
    },
    async requestShutdown () {
      this.requestingShutdown = true

      console.log('Requesting shutdown...')

      let url = this.baseUrl + '/shutdown'
      let params = {
        key: this.secret
      }
      
      this.$parent.log('Requesting shutdown...', { url, params })

      try {
        await this.$http.get(url, {
          params: params
        })

        this.$parent.log('The server has been shut down.')
      } catch (e) {
        this.$parent.log('Shutdown failed.', e)
      } finally {
        this.requestingShutdown = false
      }
    },
  },
  created () {
    setInterval(() => {
      if (this.baseUrlDefined()) {
        this.reload()
      }
    }, 3000)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.small {
  font-size: 12px;
}

</style>
