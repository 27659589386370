<template>
  <div class="log">
    <LogMessage
      v-for="(message, index) in messages"
      v-bind:key="index"
      :message="message" />
    <span class="no-data" v-if="messages.length == 0">No data.</span>
  </div>
</template>

<script>
import LogMessage from '@/components/LogMessage.vue'

export default {
  components: { LogMessage },
  props: {
    messages: Array,
  },
  watch: {
    messages () {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
