<template>
  <div class="log-message">
    <span v-if="time" class="time">{{ time }}</span>
    {{ message.msg }}
    <span v-if="message.data" class="data" :title="JSON.stringify(message.data)">data</span>
  </div>
</template>

<script>
export default {
  props: { message: Object },
  computed: {
    time () {
      if (!this.message.time) {
        return false
      }

      let date = new Date(this.message.time * 1000)

      let month = date.getMonth()+1
      switch (month) {
        case 1: month = 'Jan'; break
        case 2: month = 'Feb'; break
        case 3: month = 'Mar'; break
        case 4: month = 'Apr'; break
        case 5: month = 'May'; break
        case 6: month = 'Jun'; break
        case 7: month = 'Jul'; break
        case 8: month = 'Aug'; break
        case 9: month = 'Sep'; break
        case 10: month = 'Oct'; break
        case 11: month = 'Nov'; break
        case 12: month = 'Dec'; break
      }

      let day = date.getDate()
      day = (day < 10) ? '0'+day : day

      let hour = date.getHours()
      hour = (hour < 10) ? '0'+hour : hour

      let minute = date.getMinutes()
      minute = (minute < 10) ? '0'+minute : minute

      let second = date.getSeconds()
      second = (second < 10) ? '0'+second : second

      return `${month} ${day}; ${hour}:${minute}:${second}`
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
