<template>
  <h3 class="heading">Unreal Server Log
    <button
      @click="autoRefresh = !autoRefresh"
      :class="''+ (autoRefresh ? ' toggled-on': ' toggled-off')">Auto-Refresh</button>
    <button @click="reload()">Refresh</button>
  </h3>
  <Log :messages="messages" />
</template>

<script>
import Log from '@/components/Log.vue'

export default {
  components: { Log },
  props: {
    baseUrl: String,
    secret: String,
  },
  data () {
    return {
      messages: [],
      autoRefresh: false,
    }
  },
  watch: {
    baseUrl () {
      this.reload()
    }
  },
  methods: {
    async reload () {
      console.log('Requesting unreallog...')

      let url = this.baseUrl + '/unreallog'
      let params = {
        key: this.secret
      }

      if (this.messages.length == 0) {
        this.$parent.log('Loading Unreal server log for the first time...', { url, params })
      }

      try {
        let response = await this.$http.get(url, {
          params: params
        })

        let processedMessages = []
        if (response.data) {
          let rows = response.data.split('\n')
          for (let row of rows) {
            processedMessages.push({
              msg: row
            })
          }

          this.messages = processedMessages.reverse()
        } else {
          this.messages = []
        }
      } catch (e) {
        this.messages = []
      }
    }
  },
  created () {
    setInterval(() => {
      if (this.autoRefresh && (this.baseUrl.length >= 20)) {
        this.reload()
      }
    }, 3000)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
