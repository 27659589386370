<template>
  <h3 class="heading">Request Log</h3>
  <Log :messages="log.slice().reverse()" />
</template>

<script>
import Log from '@/components/Log.vue'

export default {
  components: { Log },
  props: { log: Array },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
